import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

import FilterIcon from '../img/filter.svg';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    transactionsHistoryBlock: {},
    transactionsHistory: {
      padding: '16px',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      '& $text': {
        color: 'rgba(255, 255, 255, 0.90)',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19px',
        letterSpacing: '0.048px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    text: {},
    sectionsSwitcher: {
      width: '100%',
      marginTop: '16px',
      [breakpoints.up(Layout.laptop)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    transactionsTable: {},
    filterButtonBlock: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginRight: '10px',
      '& $filterButton': {
        marginRight: '10px',
      },
    },
    filterButtonText: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
    },
    filterButton: {
      width: '32px',
      height: '32px',
      borderRadius: '20px',
      background: '#5D3CE1',
      backgroundImage: `url(${FilterIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      cursor: 'pointer',
      marginRight: '45px',
    },
    moreButton: {
      cursor: 'pointer',
      color: '#5D3CE1',
      display: 'flex',
      textDecoration: 'underline',
      justifyContent: 'center',
    },
    totalAmount: {
      display: 'flex',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 400,
      marginLeft: '10px',
    },
    totalAmountTitle: {},
    totalAmountCurrency: {
      marginLeft: '6px',
    },
    totalAmountValue: {
      marginLeft: '4px',
    },
  });
}
