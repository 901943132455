import { appCreateStyles } from '@core/theme/utils/create-styles';
import canceledIcon from '@modules/new-private/activity/components/wallets/components/activity-item/img/canceled.svg';
import inProgressIcon from '@modules/new-private/activity/components/wallets/components/activity-item/img/in-progress.svg';
import successfulIcon from '@modules/new-private/activity/components/wallets/components/activity-item/img/successful.svg';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      width: '100%',
      overflow: 'auto',
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 539px)`,
      borderRadius: '10px',
    },
    firstHeader: {
      borderRadius: '10px 0px 0px 0px',
    },
    lastHeader: {
      borderRadius: '0px 10px 0px 0px',
    },
    table: {
      fontSize: 15,
      '& .MuiTableCell-body': {
        color: '#FFF',
        fontSize: '13px',
        fontWeight: 400,
      },
      '& .MuiTableCell-head': {
        backgroundColor: '#2E1E4A !important',
        color: '#A4A8AB',
        fontSize: '11px',
        fontWeight: 400,
      },
      '& .MuiTableCell-root': {
        textAlign: 'left',
      },
      '& .MuiTableRow-root:nth-child(odd)': {
        backgroundColor: '#291945',
      },
      '& .MuiTableRow-root:nth-child(even)': {
        backgroundColor: '#2E1E4A',
      },
      '& .MuiTableRow-root:hover': {
        backgroundColor: '#352551',
      },
    },
    center: {
      textAlign: 'center',
    },
    loadingPage: {
      maxHeight: '100px',
    },
    loading: {
      height: '100%',
      maxHeight: '100%',
    },
    mainRow: {
      border: 0,
    },
    clickable: {
      cursor: 'pointer',
    },
    status: {
      borderRadius: '4px',
      display: 'inline-flex',
      padding: '3px 10px 5px 10px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    incoming: {
      background: '#232851',
    },
    outgoing: {
      background: '#4C304F;',
    },
    progress: {
      '$status&': {
        background: `url(${inProgressIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    inProgress: {
      '$status&': {
        background: `url(${inProgressIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    successful: {
      '$status&': {
        background: `url(${successfulIcon}) no-repeat left center`,
      },
    },
    canceled: {
      '$status&': {
        background: `url(${canceledIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    debit: {
      fontWeight: 500,
      color: '#1FAE70',
      textAlign: 'right',
      fontSize: '15px',
    },
    credit: {
      fontWeight: 500,
      color: '#FF2C2C',
      textAlign: 'right',
      fontSize: '15px',
    },
    noData: {
      width: '100%',
      height: 'calc(100% - 42px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
    },
  });
}
