import React from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { ProductType } from '@shared/models/products/product-model';
import { TransactionListQuery } from '@shared/models/transactions/list-model';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { transactionStatusePipe } from '@shared/pipes/transaction-status.pipe';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { formatDate } from '@shared/utils/date';
import classNames from 'classnames';

import { styles } from './activity-item.styles';

interface Props extends AppWithStyles<typeof styles> {
  data: TransactionListQuery;
  walletCurrency: string;
  productType: ProductType;
}

const ActivityItemComponent: React.FC<Props> = ({ classes, data, walletCurrency, productType }) => {
  const {
    date,
    value,
    cost,
    currency,
    transactionType,
    status,
    externalTransactionId,
    id,
    userEmail,
  } = data;

  const smartCoinDetailsDialogVM = React.useMemo(
    () => appInject<ISmartCoinDetailsDialogViewModel>(DI_TOKENS.smartCoinDetailsDialogViewModel),
    [],
  );

  const hasFromToBlock = () => {
    return [TransactionType.SENT, TransactionType.RECEIVED].includes(transactionType);
  };

  const isSent = () => {
    return transactionType === TransactionType.SENT;
  };

  const handleClick = () => {
    switch (transactionType) {
      case TransactionType.PURCHASED:
      case TransactionType.SOLD:
      case TransactionType.SPENT:
      case TransactionType.EARNED:
        smartCoinDetailsDialogVM.openDialog(
          externalTransactionId as string,
          OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN,
        );
        break;
      case TransactionType.WITHDRAWAL:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.WITHDRAWAL_FIAT_COIN);
        break;
      case TransactionType.DEPOSIT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.DEPOSIT_FIAT_COIN);
        break;
      case TransactionType.SENT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.SENDING_SMART_COIN);
        break;
      case TransactionType.RECEIVED:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.RECEIVING_SMART_COIN);
        break;
      default:
        break;
    }
  };

  const statusForTransactionStatus = (): string => {
    const successClass: Record<string, string | undefined> = {
      [TransactionStatus.InProgress]: classes.inProgress,
      [TransactionStatus.RequiresAction]: classes.inProgress,
      [TransactionStatus.Failed]: classes.canceled,
      [TransactionStatus.Completed]: classes.successful,
    };

    return successClass[status] || '';
  };

  let direction;
  switch (true) {
    case transactionType === TransactionType.SENT:
    case transactionType === TransactionType.SPENT:
    case transactionType === TransactionType.SOLD:
    case transactionType === TransactionType.WITHDRAWAL:
      direction = false;
      break;
    default:
      direction = true;
      break;
  }

  const directionClass = classNames({
    [classes.debit as string]: direction,
    [classes.credit as string]: !direction,
  });

  return (
    <div className={classes.item} onClick={handleClick}>
      <div className={classes.row}>
        <div className={classNames(classes.status, statusForTransactionStatus())}>
          {transactionStatusePipe(status)}
        </div>
        <span className={classNames(directionClass, statusForTransactionStatus())}>
          {direction ? '+' : '-'}
          {productType === ProductType.SMART_COIN
            ? amountPipe(value, 2)
            : minDigitsAfterDot(amountPipe(value, 2))}{' '}
          {walletCurrency}
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.date}>{formatDate(date, t`MMM D, YYYY [at] h:mm A`)}</span>
        <span className={classes.cost}>
          {cost && currency ? `${minDigitsAfterDot(amountPipe(cost))} ${currency}` : ''}
        </span>
      </div>
      {hasFromToBlock() ? (
        <div className={classes.fromToBlock}>
          <span className={classes.fromToTitle}>{isSent() ? t`To` : t`From`}</span>
          <span className={classes.fromToValue}>{userEmail}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const ActivityItem = appWithStyles(styles)(ActivityItemComponent);
