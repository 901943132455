import { appCreateStyles } from '@core/theme/utils/create-styles';
import dateIcon from '@shared/icons/transactions/date-icon.svg';
import emailIcon from '@shared/icons/transactions/email-icon.svg';

import canceledIcon from './img/canceled.svg';
import inProgressIcon from './img/in-progress.svg';
import successfulIcon from './img/successful.svg';

export function styles() {
  return appCreateStyles({
    item: {
      padding: '20px 0px 10px 0px',
      borderBottom: `1px solid rgba(238, 238, 238, 0.06)`,
      '&:last-of-type': {
        marginBottom: '46px',
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 13,

      '& + &': {
        marginTop: '10px',
      },
    },
    status: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 40,
      fontSize: '15px',
      fontWeight: 500,
      color: '#fff',
      textTransform: 'capitalize',
      minHeight: '28px',
    },
    date: {
      display: 'inline-block',
      backgroundImage: `url(${dateIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '8px',
      backgroundPositionY: 'center',
      backgroundSize: '12px',
      paddingLeft: 30,
      color: '#7B7C9E',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.065px',
    },
    progress: {
      '$status&': {
        background: `url(${inProgressIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    inProgress: {
      '$status&': {
        background: `url(${inProgressIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    successful: {
      '$status&': {
        background: `url(${successfulIcon}) no-repeat left center`,
      },
    },
    canceled: {
      '$status&': {
        background: `url(${canceledIcon}) no-repeat left center`,
      },
      '&$debit': {
        color: '#fff',
      },
      '&$credit': {
        color: '#fff',
      },
    },
    debit: {
      fontWeight: 500,
      color: '#1FAE70',
      textAlign: 'right',
      fontSize: '15px',
    },
    credit: {
      fontWeight: 500,
      color: '#FF2C2C',
      textAlign: 'right',
      fontSize: '15px',
    },
    cost: {
      color: '#79818F',
      textAlign: 'right',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.065px',
    },
    fromToBlock: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      padding: '5px 5px 5px 0px',

      '& + &': {
        marginTop: '10px',
      },
    },
    fromToTitle: {
      display: 'inline-block',
      backgroundImage: `url(${emailIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: '8px',
      backgroundPositionY: 'center',
      backgroundSize: '12px',
      paddingLeft: 30,
      color: '#7B7C9E',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.065px',
    },
    fromToValue: {
      display: 'inline-block',
      paddingLeft: 5,
      color: '#7B7C9E',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.065px',
      maxWidth: '80vh',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
}
