import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    content: {
      padding: '24px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      borderRadius: '12px',
      position: 'relative',
    },
    title: {
      color: '#fff',
      fontSize: '16px',
      fontWeight: 400,
    },
  });
}
