import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsNotEmpty } from 'class-validator';

export interface IFilterForm {
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
}

export class FilterFormFields implements IFilterForm {
  @IsNotEmpty()
  startMonth: string;

  @IsNotEmpty()
  startYear: string;

  @IsNotEmpty()
  endMonth: string;

  @IsNotEmpty()
  endYear: string;
}

export const FilterFormResolver = classValidatorResolver(FilterFormFields);
