import React, { TouchEvent, useRef, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { OpenDrawerButton } from '@shared/components/new-design/open-drawer-button/open-drawer-button';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import classNames from 'classnames';

import { styles } from './drag-and-drop.styles';

export interface WalletsProps extends AppWithStyles<typeof styles> {
  children?: React.ReactNode;
  walletName: string;
  currency: string;
  amount: number;
  isSmartCoin: boolean;
  onEndScroll?: () => void;
}

const DragAndDrop: React.FC<WalletsProps> = appObserver(
  ({ classes, children, walletName, currency, amount, isSmartCoin, onEndScroll }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isScrollInZeroPosition, setIsScrollInZeroPosition] = useState<boolean>(true);

    const scrollComponentRef = useRef<HTMLDivElement | null>(null);

    const handleViewScroll = (e: TouchEvent<HTMLDivElement>) => {
      const isZeroPosition = e.currentTarget.scrollTop === 0;
      isZeroPosition !== isScrollInZeroPosition && setIsScrollInZeroPosition(isZeroPosition);
      if (
        e.currentTarget.scrollHeight ===
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight
      ) {
        onEndScroll && onEndScroll();
      }
    };

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    return (
      <div
        ref={scrollComponentRef}
        className={classNames(classes.root, { [classes.top as string]: isOpen })}
      >
        <div className={classes.info}>
          <div className={classes.walletName}>{walletName}</div>
          <div className={classes.amountInfo}>
            <div className={classes.currency}>{currency}</div>
            <div className={classes.amount}>
              {isSmartCoin
                ? minDigitsAfterDot(amountPipe(amount, 8))
                : minDigitsAfterDot(amountPipe(amount, 2))}
            </div>
          </div>
          <div className={classes.background}></div>
        </div>
        <div className={classes.view} onScroll={handleViewScroll}>
          {!isOpen ? <OpenDrawerButton onClick={open} /> : <></>}
          {isOpen ? <CloseDrawerButton onClick={close} /> : <></>}
          {children}
        </div>
      </div>
    );
  },
);

export default appWithStyles(styles)(DragAndDrop);
