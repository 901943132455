import React from 'react';

import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export const styles = ({ breakpoints }: AppTheme) => {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      [breakpoints.up(Layout.laptop)]: {
        marginTop: '10px',
      },
    },
    content: {
      paddingTop: '18px',
      [breakpoints.up(Layout.laptop)]: {
        height: '80vmin',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 36,
      width: '100%',
      margin: '0 0 20px',
      [breakpoints.up(Layout.laptop)]: {
        position: 'absolute',
        top: '17px',
        right: '17px',
        width: '400px',
      },
    },
    controls: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0px 16px',
    },
  });
};

const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '28px',
    height: '19px',
    pointerEvents: 'none',
    position: 'absolute',
    top: '4px',
    right: '0px',
    fill: _var(CSS_VARIABLES.accentColor),
    transition: 'transform 0.2s ease-in-out',
    transformOrigin: 'center center',
  },
}))(AppSvgIcon);

export const SortIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSvgIcon
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.1113 8.75004L14.6669 11.25M14.6669 11.25L12.2224 8.75004M14.6669 11.25V3.75013"
        stroke="white"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.88867 6.24996L7.33312 3.75M7.33312 3.75L9.77756 6.24996M7.33312 3.75V11.2499"
        stroke="white"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </StyledSvgIcon>
  );
};
