import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import OrdersList from '../orders-list/orders-list';

import { styles } from './orders-mobile-container.styles';

export interface DocumentsMobileContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsMobileContainerComponent: React.FC<DocumentsMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <OrdersList />
    </div>
  );
};

export default appWithStyles(styles)(DocumentsMobileContainerComponent);
