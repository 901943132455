import { appCreateStyles } from '@core/theme/utils/create-styles';
import dateIcon from '@shared/icons/transactions/date-icon.svg';
import emailIcon from '@shared/icons/transactions/email-icon.svg';
import failedIcon from '@shared/icons/transactions/failed-icon.svg';
import inProgressIcon from '@shared/icons/transactions/in-progress-icon.svg';
import successDepositedIcon from '@shared/icons/transactions/success-deposited.svg';
import successPurchasedIcon from '@shared/icons/transactions/success-purchased.svg';
import successReceivedIcon from '@shared/icons/transactions/success-received.svg';
import successSentIcon from '@shared/icons/transactions/success-sent.svg';
import successSpentIcon from '@shared/icons/transactions/success-spent.svg';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      fontSize: '15px',
    },
    type: {
      display: 'inline-block',
      paddingLeft: '30px',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    hint: {
      padding: '0 10px 0 33px',
      background: `url(${emailIcon}) no-repeat 12px center ${_var(
        CSS_VARIABLES.backgroundMainColor,
      )}`,
      lineHeight: '30px',
      fontSize: '11px',
      fontWeight: 300,
      borderRadius: '3px',
      color: _var(CSS_VARIABLES.textSubTitlesColor),
    },
    loading: {
      padding: '30px 0',
    },
    progress: {
      background: `url(${inProgressIcon}) no-repeat left center`,
    },
    fail: {
      background: `url(${failedIcon}) no-repeat left center`,
    },
    requiresAction: {
      background: `url(${successDepositedIcon}) no-repeat left center`,
    },
    successPurchased: {
      background: `url(${successPurchasedIcon}) no-repeat left center`,
    },
    successSent: {
      background: `url(${successSentIcon}) no-repeat left center`,
    },
    successReceived: {
      background: `url(${successReceivedIcon}) no-repeat left center`,
    },
    successDeposited: {
      background: `url(${successDepositedIcon}) no-repeat left center`,
    },
    successSpent: {
      background: `url(${successSpentIcon}) no-repeat left center`,
    },
    debit: {
      fontWeight: 500,
      color: _var(CSS_VARIABLES.mainSuccess),
    },
    credit: {
      fontWeight: 500,
      color: _var(CSS_VARIABLES.mainError),
    },
    notExecuted: {
      fontWeight: 500,
      color: '#79818F',
    },
    cost: {
      display: 'inline-block',
    },
    date: {
      display: 'inline-block',
      background: `url(${dateIcon}) no-repeat 5px center`,
      paddingLeft: '30px',
      color: _var(CSS_VARIABLES.textSubTitlesColor),
    },
    link: {
      textTransform: 'uppercase',
      fontSize: '11px',
      fontWeight: 700,
      color: _var(CSS_VARIABLES.accentColor),
      cursor: 'pointer',
      textDecoration: 'none',
    },
    transactionId: {
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  });
}
