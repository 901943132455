import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 216px)`,
      overflow: 'auto',
      [breakpoints.up(Layout.laptop)]: {
        height: `calc(${_var(CSS_VARIABLES.appHeight)} - 154px)`,
        borderRadius: '10px',
      },
    },
    firstHeader: {
      [breakpoints.up(Layout.laptop)]: {
        borderRadius: '10px 0px 0px 0px',
      },
    },
    lastHeader: {
      [breakpoints.up(Layout.laptop)]: {
        borderRadius: '0px 10px 0px 0px',
      },
    },
    table: {
      fontSize: 15,
      '& .MuiTableCell-body': {
        color: '#FFF',
        fontSize: '13px',
        fontWeight: 400,
      },
      [breakpoints.down(Layout.tablet)]: {
        borderRadius: '10px',
        '& .MuiTableCell-head': {
          backgroundColor: '#15152E !important',
          color: '#7B7C9E',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '0.065px',
        },
      },
      [breakpoints.up(Layout.laptop)]: {
        fontSize: '15px',
        '& .MuiTableCell-head': {
          backgroundColor: '#2E1E4A !important',
          color: '#A4A8AB',
          fontSize: '11px',
          fontWeight: 400,
        },
        '& .MuiTableCell-root': {
          textAlign: 'left',
        },
        '& .MuiTableRow-root:nth-child(odd)': {
          backgroundColor: '#291945',
        },
        '& .MuiTableRow-root:nth-child(even)': {
          backgroundColor: '#2E1E4A',
        },
        '& .MuiTableRow-root:hover': {
          backgroundColor: '#352551',
        },
      },
    },
    center: {
      textAlign: 'center',
    },
    loadingPage: {
      maxHeight: '100px',
    },
    loading: {
      padding: '30px 0',
      [breakpoints.up(Layout.laptop)]: {
        height: '100%',
        maxHeight: '100%',
      },
    },
    mainRow: {
      border: 0,
      cursor: 'pointer',
    },
    status: {
      borderRadius: '4px',
      display: 'inline-flex',
      padding: '3px 10px 5px 10px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    purchase: {
      background: '#232851',
    },
    sell: {
      background: '#4C304F;',
    },
    noData: {
      width: '100%',
      height: 'calc(100% - 42px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
    },
  });
}
