import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { ActivityItem } from '@modules/new-private/activity/components/wallets/components/activity-item/activity-item';
import { Loading } from '@shared/components/loading';
import { ProductType } from '@shared/models/products/product-model';
import { TransactionListModel } from '@shared/models/transactions/list-model';

import { styles } from './history-table.styles';

export interface HistoryTableProps extends AppWithStyles<typeof styles> {
  walletCurrency: string;
  transactions: TransactionListModel[];
  isLoading: boolean;
  isShowCosts: boolean;
  isShowTransactionId: boolean;
  blockchainExplorerLink: string;
  productType: ProductType;
}

const HistoryTableComponent: React.FC<HistoryTableProps> = ({
  classes,
  transactions,
  walletCurrency,
  isLoading,
  productType,
}) => {
  if (isLoading)
    return (
      <div className={classes.loading}>
        <Loading size={70} />
      </div>
    );

  if (transactions.length == 0) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      {transactions.map((transaction, index) => {
        return (
          <ActivityItem
            key={index}
            data={transaction.asJson}
            walletCurrency={walletCurrency}
            productType={productType}
          />
        );
      })}
    </div>
  );
};

export const HistoryTableMobile = appWithStyles(styles)(HistoryTableComponent);
