import React, { FC, KeyboardEvent, useEffect, useMemo } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import {
  IFilterForm,
  FilterFormFields,
  FilterFormResolver,
} from '@modules/new-private/activity/components/wallets/components/filter-dialog/filter-form.validator';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { AppSelectDate } from '@shared/components/new-design/app-select-date/app-select-date';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { monthNamesList, yearsBack } from '@shared/utils/date';
import { Layout } from '@shared/utils/layout';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import { styles } from './filter-dialog.styles';

export interface FilterDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IFilterForm) => void;
  fromYear: number;
  defaultStartMonth: string | null;
  defaultStartYear: string | null;
  defaultEndMonth: string | null;
  defaultEndYear: string | null;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const FilterDialogComponent: FC<FilterDialogProps> = ({
  classes,
  onClose,
  isOpen,
  onSubmit,
  fromYear,
  defaultStartMonth,
  defaultStartYear,
  defaultEndMonth,
  defaultEndYear,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const {
    trigger,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FilterFormFields>({ resolver: FilterFormResolver });

  useEffect(() => {
    handleChangeValue(
      nameOf<IFilterForm>('startMonth'),
      defaultStartMonth || monthOptions[0]?.value || '',
    );
    handleChangeValue(
      nameOf<IFilterForm>('startYear'),
      defaultStartYear || yearOptions[yearOptions.length - 1]?.value || '',
    );
    handleChangeValue(
      nameOf<IFilterForm>('endMonth'),
      defaultEndMonth || monthOptions[monthOptions.length - 1]?.value || '',
    );
    handleChangeValue(
      nameOf<IFilterForm>('endYear'),
      defaultEndYear || yearOptions[0]?.value || '',
    );
  }, []);

  const onSubmitFrom = (data: IFilterForm) => {
    onSubmit(data);
  };

  const handleChangeValue = (field: keyof IFilterForm, value: string) => {
    setValue(field, value);
    trigger(field);
  };

  const monthOptions = useMemo(() => monthNamesList().map((m) => ({ value: m, label: m })), []);
  const yearOptions = useMemo(() => yearsBack(fromYear).map((y) => ({ value: y, label: y })), []);

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid) {
      handleSubmit(onSubmitFrom)();
    }
  };

  const filterComponent = (
    <div className={classes.viewBlock}>
      <div className={classes.title}>{t`Transaction period`}</div>
      <form
        onSubmit={handleSubmit(onSubmitFrom)}
        onKeyDown={handleKeyPress}
        className={classes.form}
      >
        <div className={classes.period}>
          <p className={classes.label}>{t`Period starts`}:</p>
          <AppSelectDate
            label={'Month'}
            isTop={true}
            options={monthOptions}
            onChangeItem={(item) =>
              handleChangeValue(nameOf<IFilterForm>('startMonth'), item as string)
            }
            defaultOption={defaultStartMonth || ''}
          />
          <AppSelectDate
            label={'Year'}
            options={yearOptions}
            onChangeItem={(item) =>
              handleChangeValue(nameOf<IFilterForm>('startYear'), item as string)
            }
            defaultOption={defaultStartYear || ''}
          />
        </div>
        <div className={classes.period}>
          <p className={classes.label}>{t`Period ends`}:</p>
          <AppSelectDate
            options={monthOptions}
            label={'Month'}
            isTop={true}
            onChangeItem={(item) =>
              handleChangeValue(nameOf<IFilterForm>('endMonth'), item as string)
            }
            defaultOption={defaultEndMonth || ''}
          />
          <AppSelectDate
            options={yearOptions}
            label={'Year'}
            onChangeItem={(item) =>
              handleChangeValue(nameOf<IFilterForm>('endYear'), item as string)
            }
            defaultOption={defaultEndYear || ''}
          />
        </div>
      </form>
      <div className={classes.submit}>
        <SubmitButton label={t`Apply Filter`} onSubmit={handleSubmit(onSubmitFrom)} />
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{filterComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{filterComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(FilterDialogComponent);
