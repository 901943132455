import React, { FC } from 'react';

// eslint-disable-next-line import/order
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

// eslint-disable-next-line import/namespace,import/default
import { t } from '@lingui/macro';

import OrdersList from '../orders-list/orders-list';

import { styles } from './orders-desktop-container.styles';

export interface DocumentsDesktopContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsDesktopContainerComponent: FC<DocumentsDesktopContainerProps> = ({ classes }) => {
  return (
    <div className={classes.content}>
      <div className={classes.title}>{t`Orders`}</div>
      <OrdersList />
    </div>
  );
};
export default appWithStyles(styles)(DocumentsDesktopContainerComponent);
