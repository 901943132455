import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      position: 'fixed',
      width: '100%',
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 300px)`,
      bottom: '0px',
      borderRadius: '16px 16px 0px 0px',
      background: '#15152E',
      transition: 'height 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    top: {
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 58px) !important`,
      '& $view': {
        height: `calc(${_var(CSS_VARIABLES.appHeight)} - 143px)`,
        overflowY: 'scroll',
      },
      '& $info': {
        display: 'flex',
      },
    },
    view: {},
    info: {
      display: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      top: '-58px',
      background: '#050217',
      width: '100%',
      height: '68px',
      padding: '0px 16px 16px 16px',
    },
    background: {
      width: '100%',
      height: '16px',
      borderRadius: '16px 16px 0px 0px',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      background: '#15152E',
    },
    walletName: {
      color: '#8182A5',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21.5px',
      letterSpacing: '0.048px',
    },
    amountInfo: {
      display: 'flex',
      color: '#8182A5',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21.5px',
      letterSpacing: '0.048px',
    },
    currency: {},
    amount: {
      marginLeft: '5px',
    },
    closeButton: {
      marginLeft: '10px',
    },
  });
}
