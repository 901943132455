import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      height: 'fit-content',
      minHeight: `calc(${_var(CSS_VARIABLES.appHeight)} - 85px)`,
      width: '100%',
      padding: '10px 0px 20px 0px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      alignSelf: 'start',
    },
    sectionsSwitcher: {
      width: '100%',
      padding: '0px 16px',
    },
  });
}
