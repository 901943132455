import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

// eslint-disable-next-line import/namespace,import/default
import Wallets from '../wallets/wallets';

import { styles } from './wallets-desktop-container.styles';

export interface WalletsDesktopContainerProps extends AppWithStyles<typeof styles> {}

const WalletsDesktopContainerComponent: FC<WalletsDesktopContainerProps> = ({ classes }) => {
  return (
    <div className={classes.content}>
      <Wallets />
    </div>
  );
};
export default appWithStyles(styles)(WalletsDesktopContainerComponent);
