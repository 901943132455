import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {},
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      minHeight: '470px',
      position: 'relative',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
    },
    form: {
      height: '200px',
      width: '100%',
      marginTop: '20px',
    },
    bold: {
      fontWeight: 600,
    },
    label: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      marginBottom: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    submit: {
      position: 'absolute',
      background: 'transparent',
      bottom: '20px',
      width: 'calc(100% - 40px)',
      display: 'flex',
      '& button + button': {
        marginLeft: '15px',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: '70%',
        height: 'auto',
        background: '#1E1D36',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    period: {
      '& + &': {
        marginTop: '22px',
      },
    },
  });
}
