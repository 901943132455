import React, { Fragment, TouchEvent } from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import Table from '@mui/material/Table';
import { Loading } from '@shared/components/loading';
import { StatusLabel } from '@shared/components/new-design/status-label';
import { StatusLabelType } from '@shared/components/new-design/status-label/status-label-type';
import { AppTableBody, AppTableCell, AppTableHead, AppTableRow } from '@shared/components/table';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { ProductType } from '@shared/models/products/product-model';
import { TransactionListModel } from '@shared/models/transactions/list-model';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { formatDate } from '@shared/utils/date';
import classNames from 'classnames';

import { styles } from './history-table-desktop.styles';

export interface HistoryTableDesktopProps extends AppWithStyles<typeof styles> {
  isLoading: boolean;
  isLoadingPage: boolean;
  transactions: TransactionListModel[];
  walletCurrency: string;
  productType: ProductType;
  onEndScroll?: () => void;
  isShowCosts: boolean;
  blockchainExplorerLink?: string;
}

const HistoryTableDesktopComponent: React.FC<HistoryTableDesktopProps> = ({
  classes: c,
  transactions,
  isLoading,
  isLoadingPage,
  walletCurrency,
  onEndScroll,
  isShowCosts,
  productType,
}) => {
  const smartCoinDetailsDialogVM = React.useMemo(
    () => appInject<ISmartCoinDetailsDialogViewModel>(DI_TOKENS.smartCoinDetailsDialogViewModel),
    [],
  );

  const getTransactionType = (): TransactionType | null => {
    if (transactions[0]) {
      return transactions[0].asJson.transactionType;
    } else {
      return null;
    }
  };

  const hasFromToBlock = (transactionType: TransactionType) => {
    return [TransactionType.SENT, TransactionType.RECEIVED].includes(transactionType);
  };

  const isSent = (transactionType: TransactionType) => {
    return transactionType === TransactionType.SENT;
  };

  const handleClick = (
    id: string,
    externalTransactionId: string | null,
    transactionType: TransactionType,
  ) => {
    switch (transactionType) {
      case TransactionType.PURCHASED:
      case TransactionType.SOLD:
      case TransactionType.SPENT:
      case TransactionType.EARNED:
        smartCoinDetailsDialogVM.openDialog(
          externalTransactionId as string,
          OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN,
        );
        break;
      case TransactionType.WITHDRAWAL:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.WITHDRAWAL_FIAT_COIN);
        break;
      case TransactionType.DEPOSIT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.DEPOSIT_FIAT_COIN);
        break;
      case TransactionType.SENT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.SENDING_SMART_COIN);
        break;
      case TransactionType.RECEIVED:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.RECEIVING_SMART_COIN);
        break;
      default:
        break;
    }
  };

  const handleViewScroll = (e: TouchEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
      onEndScroll && onEndScroll();
    }
  };

  const type = getTransactionType();

  const header = (
    <AppTableHead>
      <AppTableRow>
        {isShowCosts ? <AppTableCell>{t`Order`}</AppTableCell> : <></>}
        <AppTableCell>{t`Amount`}</AppTableCell>
        <AppTableCell>{t`Date`}</AppTableCell>
        {type && hasFromToBlock(type) && !isSent(type) ? (
          <AppTableCell>{t`From`}</AppTableCell>
        ) : (
          <></>
        )}
        {type && hasFromToBlock(type) && isSent(type) ? (
          <AppTableCell>{t`To`}</AppTableCell>
        ) : (
          <></>
        )}
        <AppTableCell>{t`Status`}</AppTableCell>
      </AppTableRow>
    </AppTableHead>
  );

  const content = (
    <div className={c.root} onScroll={handleViewScroll}>
      <Table className={c.table}>
        {header}
        <AppTableBody>
          {transactions.map((transaction) => {
            const {
              date,
              value,
              cost,
              currency,
              transactionType,
              status,
              id,
              userEmail,
              externalTransactionId,
            } = transaction.asJson;

            const statusForTransactionStatus = (): string => {
              const successClass: Record<string, string | undefined> = {
                [TransactionStatus.InProgress]: c.inProgress,
                [TransactionStatus.RequiresAction]: c.inProgress,
                [TransactionStatus.Failed]: c.canceled,
                [TransactionStatus.Completed]: c.successful,
              };

              return successClass[status] || '';
            };

            let direction;
            switch (true) {
              case transactionType === TransactionType.SENT:
              case transactionType === TransactionType.SPENT:
              case transactionType === TransactionType.SOLD:
              case transactionType === TransactionType.WITHDRAWAL:
                direction = false;
                break;
              default:
                direction = true;
                break;
            }

            const directionClass = classNames({
              [c.debit as string]: direction,
              [c.credit as string]: !direction,
            });

            return (
              <Fragment key={id}>
                <AppTableRow
                  className={c.clickable}
                  onClick={() => handleClick(id, externalTransactionId, transactionType)}
                >
                  {isShowCosts ? (
                    <AppTableCell className={c.mainRow}>{`${minDigitsAfterDot(
                      amountPipe(cost, 2),
                    )} ${currency}`}</AppTableCell>
                  ) : (
                    <></>
                  )}
                  <AppTableCell className={c.mainRow}>
                    <span className={classNames(directionClass, statusForTransactionStatus())}>
                      {direction ? '+' : '-'}
                      {productType === ProductType.SMART_COIN
                        ? minDigitsAfterDot(amountPipe(value, 8))
                        : minDigitsAfterDot(amountPipe(value, 2))}{' '}
                      {walletCurrency}
                    </span>
                  </AppTableCell>
                  <AppTableCell className={c.mainRow}>
                    <span>{formatDate(date, t`MMM D, YYYY [at] h:mm A`)}</span>
                  </AppTableCell>
                  {type && hasFromToBlock(type) && !isSent(type) ? (
                    <AppTableCell className={c.mainRow}>{userEmail}</AppTableCell>
                  ) : (
                    <></>
                  )}
                  {type && hasFromToBlock(type) && isSent(type) ? (
                    <AppTableCell className={c.mainRow}>{userEmail}</AppTableCell>
                  ) : (
                    <></>
                  )}
                  <AppTableCell className={c.mainRow}>
                    <StatusLabel type={StatusLabelType.TRANSACTION} status={status} />
                  </AppTableCell>
                </AppTableRow>
              </Fragment>
            );
          })}
        </AppTableBody>
      </Table>
      {isLoadingPage ? (
        <div className={c.loadingPage}>
          <Loading size={70} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const noContent = (
    <div className={c.root}>
      <div className={c.noData}>{t`It looks like You still have no transactions`}</div>
    </div>
  );

  if (isLoading)
    return (
      <div className={c.loading}>
        <Loading size={70} />
      </div>
    );

  return transactions.length ? content : noContent;
};

export const HistoryTableDesktop = appWithStyles(styles)(HistoryTableDesktopComponent);
