import React, { useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DragAndDrop } from '@modules/new-private/activity/components/drag-and-drop';
import { IFilterForm } from '@modules/new-private/activity/components/wallets/components/filter-dialog/filter-form.validator';
import { HistoryTableDesktop } from '@modules/new-private/activity/components/wallets/components/history-table-desktop';
import { HistoryTableMobile } from '@modules/new-private/activity/components/wallets/components/history-table-mobile/history-table';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { Tabs } from '@shared/components/new-design/tabs';
import {
  ToggleButton,
  ToggleSizeEnum,
  ToggleTypeEnum,
} from '@shared/components/new-design/toggle-button/toggle-button';
import WalletsCarouselMobile from '@shared/components/new-design/wallets-carousel';
import WalletsCarouselDesktop from '@shared/components/wallets-carousel/wallets-carousel';
import { ProductType } from '@shared/models/products/product-model';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { reaction } from 'mobx';
import { useMutation, useQuery } from 'react-query';

import { FilterDialog } from '../filter-dialog';
import { WalletsViewModel } from './wallets.vm';

import { styles } from './wallets.styles';

export interface WalletsProps extends AppWithStyles<typeof styles> {}

const Wallets: React.FC<WalletsProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new WalletsViewModel(), []);
  const [isOpenFilterDialog, setIsOpenFilterDialog] = useState<boolean>(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  reaction(
    () => $vm.activeCurrency,
    () => $vm.calculateTotalAmount(),
  );

  const fetchWalletsQuery = useQuery(['fetch-wallets-list'], () => $vm.fetchWallets(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const fetchTransactionsQuery = useQuery(
    ['fetch-transactions-list', $vm.activeTab, $vm.walletId],
    () => $vm.fetchTransactions(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchTransactionsPageMutation = useMutation((data: { page: number }) =>
    $vm.fetchTransactionsPage(data.page),
  );

  useEffect(() => {
    $vm.subscribeForOrders(() => {
      fetchWalletsQuery.refetch();
      fetchTransactionsQuery.refetch();
      fetchTransactionsPageMutation.mutate({ page: $vm.page });
    });
  }, []);

  const handleAddFilter = (date: IFilterForm) => {
    $vm.addDateFilter(date);
    setIsOpenFilterDialog(false);
    fetchTransactionsQuery.refetch();
  };

  const handleResetFilter = () => {
    $vm.removeDateFilter();
    setIsOpenFilterDialog(false);
    fetchTransactionsQuery.refetch();
  };

  const handleNextPage = () => {
    if ($vm.hasNextPage) {
      $vm.nextPage();
      fetchTransactionsPageMutation.mutate({ page: $vm.page });
    }
  };

  if (fetchWalletsQuery.isFetching) {
    return (
      <div className={classNames(classes.root, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  }

  const desktopWallets = (
    <div className={classNames(classes.root, 'invisible-content')}>
      <WalletsCarouselDesktop
        wallets={$vm.wallets}
        selectable={true}
        onSelectWallet={(wallet: WalletsListModel) => $vm.setActiveWallet(wallet)}
        totalAmount={`${t`Account balance`} ${$vm.activeBaseCurrency} ${$vm.totalAmount}`}
      />
      <div className={classes.transactionsHistoryBlock}>
        <div className={classes.transactionsHistory}>
          <div className={classes.title}>
            <div className={classes.text}>{t`Transactions history`}</div>
          </div>
          <div className={classes.sectionsSwitcher}>
            <Tabs
              options={$vm.tabs}
              selected={$vm.activeTab}
              onChange={(item) => $vm.setActiveTab(item)}
            />
            <div className={classes.filterButtonBlock} onClick={() => setIsOpenFilterDialog(true)}>
              <div className={classes.filterButton}></div>
              <div className={classes.filterButtonText}>{t`Transactions period`}</div>
            </div>
          </div>
          <div className={classes.transactionsTable}>
            <HistoryTableDesktop
              onEndScroll={handleNextPage}
              isLoading={fetchTransactionsQuery.isFetching}
              isLoadingPage={fetchTransactionsPageMutation.isLoading}
              walletCurrency={$vm.walletCurrency || ''}
              transactions={$vm.transactions}
              productType={$vm.walletProductType}
              isShowCosts={$vm.isShowCosts}
              blockchainExplorerLink={$vm.blockchainExplorerLink}
            />
            {isMobile && fetchTransactionsPageMutation.isLoading ? <Loading size={30} /> : <></>}
          </div>
        </div>
      </div>
      <FilterDialog
        isOpen={isOpenFilterDialog}
        onClose={handleResetFilter}
        onSubmit={handleAddFilter}
        fromYear={$vm.fromYear}
        defaultStartMonth={$vm.defaultStartMonth}
        defaultStartYear={$vm.defaultStartYear}
        defaultEndMonth={$vm.defaultEndMonth}
        defaultEndYear={$vm.defaultEndYear}
      />
    </div>
  );

  const mobileWallets = (
    <div className={classes.root}>
      <WalletsCarouselMobile
        wallets={$vm.wallets}
        onSelectWallet={(wallet: WalletsListModel) => $vm.setActiveWallet(wallet)}
      />
      <DragAndDrop
        walletName={$vm.walletName}
        currency={$vm.walletCurrency as string}
        amount={$vm.walletBalance as number}
        isSmartCoin={$vm.walletProductType === ProductType.SMART_COIN}
        onEndScroll={handleNextPage}
      >
        <div className={classes.transactionsHistory}>
          <div className={classes.title}>
            <div className={classes.text}>{t`Transactions history`}</div>
            <div className={classes.filterButton} onClick={() => setIsOpenFilterDialog(true)}></div>
          </div>
          <div className={classes.sectionsSwitcher}>
            <ToggleButton
              options={$vm.tabs}
              selected={$vm.activeTab}
              onChange={(item) => $vm.setActiveTab(item)}
              type={ToggleTypeEnum.GREY}
              size={ToggleSizeEnum.LARGE}
            />
          </div>
          <div className={classes.transactionsTable}>
            <HistoryTableMobile
              isLoading={fetchTransactionsQuery.isFetching}
              walletCurrency={$vm.walletCurrency || ''}
              blockchainExplorerLink={$vm.blockchainExplorerLink}
              transactions={$vm.transactions}
              isShowCosts={$vm.isShowCosts}
              isShowTransactionId={false}
              productType={$vm.walletProductType}
            />
            {fetchTransactionsPageMutation.isLoading ? <Loading size={30} /> : <></>}
          </div>
        </div>
      </DragAndDrop>
      <FilterDialog
        isOpen={isOpenFilterDialog}
        onClose={handleResetFilter}
        onSubmit={handleAddFilter}
        fromYear={$vm.fromYear}
        defaultStartMonth={$vm.defaultStartMonth}
        defaultStartYear={$vm.defaultStartYear}
        defaultEndMonth={$vm.defaultEndMonth}
        defaultEndYear={$vm.defaultEndYear}
      />
    </div>
  );

  return isMobile ? mobileWallets : desktopWallets;
});

export default appWithStyles(styles)(Wallets);
