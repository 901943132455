import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { t } from '@lingui/macro';
import { IFilterForm } from '@modules/new-private/activity/components/wallets/components/filter-dialog/filter-form.validator';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductType } from '@shared/models/products/product-model';
import { TransactionListModel } from '@shared/models/transactions/list-model';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IConfigService } from '@shared/types/config-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { dateUtils } from '@shared/utils/date';
import { BigNumber } from 'bignumber.js';
import { reaction } from 'mobx';

export class WalletsViewModel {
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private smartCoinTracinkService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  private _wallets: Array<WalletsListModel> = [];
  private _transactions: TransactionListModel[] = [];
  private _wallet = {
    data: null as WalletsListModel | null,
  };
  private _activeTab = {
    data: TransactionType.PURCHASED as string,
  };
  private _defaultStartMonth: string | null;
  private _defaultStartYear: string | null;
  private _defaultEndMonth: string | null;
  private _defaultEndYear: string | null;
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private _perPage = 20;
  private _page = 1;
  private _hasNext = false;
  private _totalAmount = {
    data: '0.00' as string,
  };

  get totalAmount() {
    return this._totalAmount.data;
  }

  get defaultStartMonth() {
    return this._defaultStartMonth;
  }

  get defaultStartYear() {
    return this._defaultStartYear;
  }
  get defaultEndMonth() {
    return this._defaultEndMonth;
  }
  get defaultEndYear() {
    return this._defaultEndYear;
  }

  get _fromDate() {
    if (this._defaultStartMonth && this._defaultStartYear) {
      return dateUtils(`${this._defaultStartMonth} ${this._defaultStartYear}`, 'MMMM YYYY');
    } else {
      return null;
    }
  }

  get _toDate() {
    if (this._defaultEndMonth && this._defaultEndYear) {
      return dateUtils(`${this._defaultEndMonth} ${this._defaultEndYear}`, 'MMMM YYYY').endOf(
        'month',
      );
    } else {
      return null;
    }
  }

  get activeCurrency() {
    return this.ratesVM.currency;
  }

  get rate(): number {
    return this.ratesVM.rate[this.activeCurrency] as number;
  }

  get fromYear() {
    return this.configService.filteringFromYear;
  }

  get transactions() {
    return this._transactions;
  }

  get isShowCosts(): boolean {
    return this._activeTab.data === TransactionType.PURCHASED;
  }

  get blockchainExplorerLink() {
    return `${this.configService.blockchainViewerOrigin}`;
  }

  get page() {
    return this._page;
  }

  get hasNextPage() {
    return this._hasNext;
  }

  get activeTab() {
    return this._activeTab.data;
  }

  get activeBaseCurrency() {
    return this.productsVM.getProductByCurrency(this.activeCurrency)?.asJson.baseCurrency || '';
  }

  setActiveTab(value: string) {
    this._activeTab.data = value;
  }

  get wallets() {
    return this._wallets;
  }

  setActiveWallet(wallet: WalletsListModel) {
    this._wallet.data = wallet;
  }

  get walletId() {
    return this._wallet.data?.asJson.id;
  }

  get walletCurrency() {
    return this._wallet.data?.asJson.currency;
  }

  get walletBalance() {
    return this._wallet.data?.asJson.balance;
  }

  get walletName() {
    return this.walletProductType === ProductType.SMART_COIN
      ? `${this.walletProduct?.asJson.baseCurrency || ''} ${t`account`}`
      : `${this.walletProduct?.asJson.name || ''} ${t`wallet`}`;
  }

  get walletProductType() {
    return this.productsVM.getProductTypeByCurrency(
      this.walletCurrency || ProductType.SMART_COIN,
    ) as ProductType;
  }

  get walletProduct() {
    return this.productsVM.getProductByCurrency(this.walletCurrency as string);
  }

  get tabs() {
    if (!this._wallet.data) return [];
    const { currency } = this._wallet.data.asJson;

    if (this.productsVM.getProductTypeByCurrency(currency) === ProductType.SMART_COIN) {
      return [
        {
          label: t`Purchased`,
          id: TransactionType.PURCHASED,
        },
        {
          label: t`Sold`,
          id: TransactionType.SOLD,
        },
        {
          label: t`Sent`,
          id: TransactionType.SENT,
        },
        {
          label: t`Received`,
          id: TransactionType.RECEIVED,
        },
      ];
    } else if (this.productsVM.getProductTypeByCurrency(currency) === ProductType.FIAT_COIN) {
      return [
        {
          label: t`Deposited`,
          id: TransactionType.DEPOSIT,
        },
        {
          label: t`Spent`,
          id: TransactionType.SPENT,
        },
        {
          label: t`Earned`,
          id: TransactionType.EARNED,
        },
        {
          label: t`Withdrawn`,
          id: TransactionType.WITHDRAWAL,
        },
      ];
    } else {
      return [];
    }
  }

  subscribeForOrders(cb: () => void) {
    this.smartCoinTracinkService.subscribeForExecutedOrder('wallets', cb);
  }

  constructor() {
    appMakeObservable(this, {
      _wallet: appObservable,
      _activeTab: appObservable,
      _totalAmount: appObservable,
    });
    reaction(
      () => this.walletId,
      () => this.checkTabs(),
    );
  }

  checkTabs() {
    this.tabs[0] && (this._activeTab.data = this.tabs[0]?.id);
  }

  fetchWallets = async () => {
    this._wallets = await this.walletsService.getList(Currency.USDT, true);
    if (!this._wallet.data && this._wallets.length) {
      this._wallet.data = this._wallets[0] as WalletsListModel;
    }
    this.calculateTotalAmount();
  };

  nextPage() {
    this._page += 1;
  }

  fetchTransactions = async () => {
    this._page = 1;
    this._hasNext = false;
    if (!this._wallet.data || !this._activeTab.data) return;
    const response = await this.walletsService.getWalletTransactions(
      this._wallet.data.asJson.id,
      this._activeTab.data as TransactionType,
      this._perPage,
      this._page,
      this._fromDate as Date | null,
      this._toDate as Date | null,
    );
    this._hasNext = response.asJson.hasNext;
    this._transactions = response.list;
  };

  fetchTransactionsPage = async (page: number) => {
    if (!this._wallet.data || !this._activeTab.data) return;
    if (page === 1) return;
    const response = await this.walletsService.getWalletTransactions(
      this._wallet.data.asJson.id,
      this._activeTab.data as TransactionType,
      this._perPage,
      page,
      this._fromDate as Date | null,
      this._toDate as Date | null,
    );
    this._hasNext = response.asJson.hasNext;
    this._transactions.push(...response.list);
  };

  addDateFilter = (date: IFilterForm) => {
    this._defaultStartMonth = date.startMonth;
    this._defaultStartYear = date.startYear;
    this._defaultEndMonth = date.endMonth;
    this._defaultEndYear = date.endYear;
  };

  removeDateFilter = () => {
    this._defaultStartMonth = null;
    this._defaultStartYear = null;
    this._defaultEndMonth = null;
    this._defaultEndYear = null;
  };

  calculateTotalAmount() {
    let totalAmountInFiatCurrencyBigNumber = BigNumber(0);
    let totalAmountInBaseFiatCurrencyBigNumber = BigNumber(0);

    this._wallets.forEach((wallet) => {
      totalAmountInFiatCurrencyBigNumber = totalAmountInFiatCurrencyBigNumber.plus(
        wallet.asJson.balanceInFiatCurrency,
      );
      totalAmountInBaseFiatCurrencyBigNumber = totalAmountInBaseFiatCurrencyBigNumber.plus(
        wallet.asJson.balanceInBaseFiatCurrency,
      );
    });

    this._totalAmount.data =
      this.activeCurrency === Currency.USDT
        ? totalAmountInFiatCurrencyBigNumber.toFixed(2)
        : totalAmountInBaseFiatCurrencyBigNumber.toFixed(2);
  }
}
