import React, { useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import {
  ToggleButton,
  ToggleSizeEnum,
  ToggleTypeEnum,
} from '@shared/components/new-design/toggle-button/toggle-button';

import { MobileOrders } from '../orders-list';
import Wallets from '../wallets/components/wallets/wallets';

import { styles } from './mobile-activity.styles';

enum SectionEnum {
  WALLETS = 'accounts',
  ORDERS = 'orders',
}

const Sections = [
  { id: SectionEnum.WALLETS, label: t`Accounts` },
  { id: SectionEnum.ORDERS, label: t`Orders` },
];

export interface MobileActivityProps extends AppWithStyles<typeof styles> {}

const MobileActivity: React.FC<MobileActivityProps> = ({ classes }) => {
  const [activeSection, setActiveSection] = useState<SectionEnum | string>(SectionEnum.WALLETS);

  let content = <></>;
  if (activeSection === SectionEnum.WALLETS) {
    content = <Wallets />;
  } else if (activeSection === SectionEnum.ORDERS) {
    content = <MobileOrders />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.sectionsSwitcher}>
        <ToggleButton
          options={Sections}
          selected={activeSection}
          onChange={(item) => {
            setActiveSection(item);
          }}
          type={ToggleTypeEnum.GREY}
          size={ToggleSizeEnum.LARGE}
        />
      </div>
      {content}
    </div>
  );
};

export default appWithStyles(styles)(MobileActivity);
