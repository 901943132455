import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { Theme, useMediaQuery } from '@mui/material';
import { Layout } from '@shared/utils/layout';

import MobileActivity from './components/mobile-activity/mobile-activity';

import { styles } from './activity.styles';

export interface ActivityProps extends AppWithStyles<typeof styles> {}

const Activity: React.FC<ActivityProps> = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  if (isMobile) {
    return <MobileActivity />;
  } else {
    return <div>DESKTOP ACTIVITY</div>;
  }
};

export default appWithStyles(styles)(Activity);
