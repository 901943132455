import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { OrdersFilteringOptions } from '@shared/models/orders/orders-filtering-options';
import { OrdersSortingOptions } from '@shared/models/orders/orders-sorting-options';
import { SmartCoinOrderListModel } from '@shared/models/orders/smart-coin-order-list-model';
import { smartCoinOrderInternalStatusPipe } from '@shared/pipes/smart-coin-order-status.pipe';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';
import { IRatesVM } from '@shared/types/rates-vm';
import {
  SmartCoinOrderStatusExternal,
  SmartCoinOrderStatusInternal,
} from '@shared/types/smart-coin-order-status';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';

export class OrdersListViewModel {
  private orderSmartCoinService = appInject<IOrdersSmartCoinService>(
    DI_TOKENS.ordersSmartCoinService,
  );
  private smartCoinTracinkService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);

  private _orders: SmartCoinOrderListModel[] = [];
  private _perPage = 20;
  private _page = 1;
  private _hasNext = false;

  private _sortBy: OrdersSortingOptions = OrdersSortingOptions.NewestToOldest;
  private _filterBy: OrdersFilteringOptions = OrdersFilteringOptions.None;

  private readonly orderFilterStatuses: Record<
    OrdersFilteringOptions,
    SmartCoinOrderStatusInternal[] | undefined
  > = {
    [OrdersFilteringOptions.None]: undefined,
    [OrdersFilteringOptions.Secure3D]: smartCoinOrderInternalStatusPipe(
      SmartCoinOrderStatusExternal.SECURE_3D,
    ),
    [OrdersFilteringOptions.InProgress]: smartCoinOrderInternalStatusPipe(
      SmartCoinOrderStatusExternal.IN_PROGRESS,
    ),
    [OrdersFilteringOptions.Executed]: smartCoinOrderInternalStatusPipe(
      SmartCoinOrderStatusExternal.EXECUTED,
    ),
    [OrdersFilteringOptions.Canceled]: smartCoinOrderInternalStatusPipe(
      SmartCoinOrderStatusExternal.CANCELED,
    ),
  };

  constructor() {
    appMakeObservable(this, {
      _orders: appObservable,
      _sortBy: appObservable,
      _filterBy: appObservable,
    });
  }

  get hasNextPage() {
    return this._hasNext;
  }

  get page() {
    return this._page;
  }

  get orders() {
    return this._orders;
  }

  get rate(): number {
    return this.ratesVM.rate[this.currency] as number;
  }

  get currency() {
    return this.ratesVM.currency;
  }

  get filterBy() {
    return this._filterBy;
  }

  get sortBy() {
    return this._sortBy;
  }

  get filterOptions() {
    return [
      { value: OrdersFilteringOptions.None, label: t`Show All orders` },
      { value: OrdersFilteringOptions.InProgress, label: t`Orders in progress` },
      { value: OrdersFilteringOptions.Secure3D, label: t`Required 3D Secure` },
      { value: OrdersFilteringOptions.Executed, label: t`Executed orders` },
      { value: OrdersFilteringOptions.Canceled, label: t`Canceled orders` },
    ];
  }

  get sortOptions() {
    return [
      { value: OrdersSortingOptions.NewestToOldest, label: t`Newest to oldest` },
      { value: OrdersSortingOptions.OldestToNewest, label: t`Oldest to newest` },
    ];
  }

  nextPage() {
    this._page += 1;
  }

  handleFilterBy = (event: any) => {
    this._page = 1;
    this._filterBy = Number(event.target.value) as OrdersFilteringOptions;
  };

  handleSortBy = (event: any) => {
    this._sortBy = Number(event.target.value) as OrdersSortingOptions;
  };

  subscribeForOrders(cb: () => void) {
    this.smartCoinTracinkService.subscribeForExecutedOrder('wallets', cb);
  }

  fetchOrders = async () => {
    this._page = 1;
    this._hasNext = false;
    const order: Record<OrdersSortingOptions, boolean> = {
      [OrdersSortingOptions.NewestToOldest]: false,
      [OrdersSortingOptions.OldestToNewest]: true,
    };
    const response = await this.orderSmartCoinService.getList({
      status: this.orderFilterStatuses[this.filterBy],
      orderBy: 'OrderDate',
      asc: order[this._sortBy],
      page: this._page,
      perPage: this._perPage,
    });
    this._hasNext = response.asJson.hasNext;
    this._orders = response.list;
  };

  fetchOrdersPage = async (page: number) => {
    if (page === 1) return;
    const order: Record<OrdersSortingOptions, boolean> = {
      [OrdersSortingOptions.NewestToOldest]: false,
      [OrdersSortingOptions.OldestToNewest]: true,
    };
    const response = await this.orderSmartCoinService.getList({
      status: this.orderFilterStatuses[this.filterBy],
      orderBy: 'OrderDate',
      asc: order[this._sortBy],
      page: page,
      perPage: this._perPage,
    });
    this._hasNext = response.asJson.hasNext;
    this._orders.push(...response.list);
  };
}
