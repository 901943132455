import React, { useEffect } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { ErrorHandler } from '@shared/components/error-handler';
import { SmallSelect } from '@shared/components/new-design/small-select';
import { Typography } from '@shared/components/typography';
import { OrdersFilteringOptions } from '@shared/models/orders/orders-filtering-options';
import { OrdersSortingOptions } from '@shared/models/orders/orders-sorting-options';
import { scrollToTop } from '@shared/utils/window';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import { OrdersTable } from '../orders-table';
import { OrdersListViewModel } from './orders-list.vm';

import { SortIcon, styles } from './orders-list.styles';

export interface OrdersListProps extends AppWithStyles<typeof styles> {}

const OrdersListComponent: React.FC<OrdersListProps> = appObserver(({ classes }) => {
  const $vm = React.useMemo(() => new OrdersListViewModel(), []);

  const fetchOrdersQuery = useQuery(
    ['fetch-orders-list', $vm.sortBy, $vm.filterBy],
    () => $vm.fetchOrders(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const fetchOrdersPageMutation = useMutation(
    (data: { page: number }) => $vm.fetchOrdersPage(data.page),
    {},
  );

  useEffect(() => {
    scrollToTop();
  }, [$vm.sortBy, $vm.filterBy]);

  if (fetchOrdersPageMutation.isError || fetchOrdersQuery.isError) {
    return (
      <ErrorHandler heading={<Typography variant="h3">{t`Something went wrong`}</Typography>} />
    );
  }

  useEffect(() => {
    $vm.subscribeForOrders(() => {
      fetchOrdersQuery.refetch();
    });
  }, []);

  const handleNextPage = () => {
    if ($vm.hasNextPage) {
      $vm.nextPage();
      fetchOrdersPageMutation.mutate({ page: $vm.page });
    }
  };

  const isDisabled = $vm.filterBy === OrdersFilteringOptions.None && $vm.orders.length === 0;

  return (
    <div className={classNames(classes.root, 'invisible-content')}>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.controls}>
            <SmallSelect
              options={$vm.sortOptions}
              IconComponent={SortIcon}
              value={$vm.sortBy}
              onChange={$vm.handleSortBy}
              active={$vm.sortBy !== OrdersSortingOptions.NewestToOldest}
              disabled={isDisabled}
            />
            <SmallSelect
              options={$vm.filterOptions}
              value={$vm.filterBy}
              onChange={$vm.handleFilterBy}
              active={$vm.filterBy !== OrdersFilteringOptions.None}
              disabled={isDisabled}
            />
          </div>
        </div>
        <OrdersTable
          onEndScroll={handleNextPage}
          isLoading={fetchOrdersQuery.isFetching}
          isLoadingPage={fetchOrdersPageMutation.isLoading}
          orders={$vm.orders}
          currency={$vm.orders.length ? $vm.orders[0]?.asJson.smartCoin || '' : ''}
        />
      </div>
    </div>
  );
});

export default appWithStyles(styles)(OrdersListComponent);
